.root-container {
    /*
    Main container, contains root-right and root-content
     */
    display: flex;
    height: 100vh; /* Set the container to full height */
    flex-direction: column;
}

.root-content-container {
    flex-grow: 1; /* Allow the container to grow and fill the remaining vertical space */
    display: flex;
    position: relative;
}

.root-right {
    /*
    Navbar on the right side, vertical
     */
    overflow-y: auto; /* Enable vertical scrolling for the navbar */
    flex-shrink: 0; /* Prevent the navbar from shrinking */

}

.root-content {
    /*
    Content container, fills the rest of the space on the left
    Contains <Outlet>
     */
    flex-grow: 1;
    position: relative;
    overflow-y: auto;

}

.announcement-banner {
    /*
    Banner at the top of the page,
    above both the navbar and the content
     */
    padding: 0.5rem;
    text-align: center;
    background: var(--banner-color-primary) repeating-linear-gradient(45deg, var(--banner-color-secondary), var(--banner-color-secondary) 12px, var(--banner-color-primary) 5px, var(--banner-color-primary) 25px);
}

.announcement-banner-text {
    color: var(--banner-text-color);
    font-size: 1.2rem;
    font-weight: 600;

}

/**
Super crappy buttons :/
 */

.button {
    background-color: var(--pink-d);
    outline: none;
    border-radius: 0.2rem;
    border-width: 0;
    border-bottom-width: 3px;
    border-right-width: 3px;
    filter: drop-shadow(0.2rem 0.15rem 0.05rem var(--dark-d));
    padding: 0.5rem;
    font-family: "Belanosima", sans-serif;
    font-size: 1.1rem;
    border-color: var(--pink-d);
}

.button-green {
    background-color: var(--green);
    border-color: var(--green);
}

.button:disabled {
    cursor: not-allowed;
}

.button:hover {
    background-color: var(--pink);
    border-color: var(--pink);
}

.button-green:hover {
    background-color: var(--green);
    filter: brightness(110%) drop-shadow(0.2rem 0.15rem 0.05rem var(--dark-d));
    border-color: var(--green);
}

.button:hover:disabled {
    background-color: var(--pink-d);
    border-color: var(--pink-d);
}

.button-green:hover:disabled {
    background-color: var(--green);
    border-color: var(--green);
}

.button:active {
    filter: drop-shadow(0.1rem 0.1rem 0.05rem var(--dark-d));
}


.button:active:disabled {
    filter: drop-shadow(0.2rem 0.15rem 0.05rem var(--dark-d));
}

.no-style-link {
    text-decoration: none;
    color: inherit;
}

.no-style-link:visited {
    text-decoration: none;
    color: inherit;
}

.no-style-link:hover {
    text-decoration: none;
    color: inherit;
}

a {
    color: unset;
    text-decoration: unset;
}