/* Define fonts! */
@font-face {
  font-family: 'Belanosima';
  src: url('../assets/Belanosima-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Belanosima';
  src: url('../assets/Belanosima-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Belanosima';
  src: url('../assets/Belanosima-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

/* Define color palette */
:root {
  --white: #f5f5f5;
  --white-d: #ececec;
  --pink: #ffc6d9;
  --pink-d: #ffa8c5;
  --coral: #fe938c;
  --coral-d: #fe7970;
  --blue: #51c4e1;
  --blue-d: #08b5e1;
  --yellow: #fff7ae;
  --yellow-d: #e5dd6e;
  --dark: #2d3047;
  --dark-d: #202232;
  --purple: #4b284d;
  --purple-d: #271528;
  --green: #73e2a7;
  --green-d: #1c7c54;

}

body {
  margin: 0;
  background-color: var(--background);
  font-family: 'Belanosima', sans-serif;
  font-weight: normal;
  font-style: normal;
}

.background-text {
    color: var(--background-text);
    font-weight: normal;
}

.background-text a {
    color: var(--background-link);
}

.background-text a:visited {
  color: var(--background-link-visited);
}

.background-text a:hover {
  color: var(--background-link-hover);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 0.33rem;               /* width of the entire scrollbar */
  height: 0.33rem;
}

::-webkit-scrollbar-track {
  background: transparent        /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-color);    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
}

:root {
  scrollbar-width: thin;          /* "auto" or "thin" */
  scrollbar-color: var(--scrollbar-color) transparent;   /* scroll thumb and track */
}

.text-aligned-icon {
    vertical-align: text-bottom;
}