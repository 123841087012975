.card {
    /* Removing this position: relative has a pretty funny effect on the home page
     TODO: turn into easter egg (needs funny animation) */
    position: relative;
    white-space: pre-wrap;
    border-radius: 3px;
    display: inline-block;
    align-self: center;
    padding: 0.5rem;
    overflow: hidden;
    z-index: 0;
    margin: 0.5rem;
    max-width: 85vw;
    min-height: max-content;
    text-align: unset;
}

.card-header {
    font-weight: 600;
    font-size: 1.5rem;
    color: var(--card-text);
    margin: 0.25rem;
    z-index: 1;
}

.card-body {
    font-size: 1.2rem;
    margin: 0.25rem;
    color: var(--card-text);
    z-index: 1;
}

.card-bg-text {
    font-size: 18rem;
    font-weight: 600;
    position: absolute;
    z-index: -1;
    user-select: none;
}