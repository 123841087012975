.navbar {
    background-color: var(--nav-background);
    width: 20rem;
    height: 100%;
    position: sticky;
    top: 0;
    right: 0;
}

.navbar-header {
    color: var(--nav-header-text);
    font-weight: 600;
    font-size: 2.25rem;
    padding: 0.5rem;
    text-align: center;
}

.navbar-header-wc {
    font-size: 2.1rem;
}

.navbar-item-link-text {
    margin: 0 0.5rem;
}

.navbar-item {
    color: var(--nav-text);
    font-size: 1.33rem;
    margin: 0.25rem 3.25rem;
}

.navbar-item-logout .navbar-item-link-text {
    color: var(--nav-text);
}

.navbar-item-logout .text-aligned-icon {
    color: var(--nav-text);
}

.navbar-item a {
    color: var(--nav-link);
    text-decoration: none;
}

.navbar-item a:visited {
    color: var(--nav-link-visited);
}

.navbar-item a:hover {
    color: var(--nav-link-hover);
}